/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import ToggleAnalytics from "./src/utils/Analytics"

// You can delete this file if you're not using it
import "./src/styles/global.css"

function checkThings() {
  const thingsToWaitFor = document.getElementsByClassName("brand-wait")
  let result = 0
  for (let i = 0; i < thingsToWaitFor.length; i++) {
    result++
  }
  return result
}
function isPageReady() {
  let interval = setInterval(frame, 500)
  const loader = document.getElementById("___loader")
  const gatsby = document.getElementById("___gatsby")
  const body = document.getElementById("body")

  frame()

  function frame() {
    if (checkThings() === 0) {
      clearInterval(interval)
      loader?.classList.add("brand-loader-hide")
    }
  }
}

// Called when the initial (but not subsequent) render of Gatsby App is done on the client.
export const onInitialClientRender = () => {
  isPageReady()
}

export const onRouteUpdate = () => {
  const loader = document.getElementById("___loader")
  loader?.classList.remove("brand-loader-hide")
  ToggleAnalytics()
  isPageReady()
}
